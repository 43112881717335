export const apiUrl = process.env.NEXT_PUBLIC_API_URL;

type QueryParam = number | string;

export const apiRoutes = {
  share: {
    _root: `${apiUrl}/share/`,
    container: (containerId: QueryParam) => ({
      _root: `${apiUrl}/share/${containerId}/`,
      candidate: (candidateId: QueryParam) => ({
        _root: `${apiUrl}/share/${containerId}/candidates/${candidateId}/`,
        pdf: (pdfId: QueryParam) =>
          `${apiUrl}/share/${containerId}/candidates/${candidateId}/pdf/${pdfId}/`,
      }),
    }),
  },
  candidates: {
    _root: `${apiUrl}/application/`,
    applyAndUpdateProfile: `${apiUrl}/application/apply-and-update-profile/`,
    createManually: `${apiUrl}/application/create-manually/`,
  },
  offers: {
    _root: `${apiUrl}/offer/`,
    offer: (offerId: QueryParam) => `${apiUrl}/offer/${offerId}/`,
    recommended: `${apiUrl}/offer/recommended/`,
  },
  employer: {
    candidates: `${apiUrl}/employer/candidates/`,
  },
  crmrequest: `${apiUrl}/crmrequest/`,
  browser: {
    applications: {
      candidate: (candidateId: QueryParam) =>
        `${apiUrl}/browser/applications/${candidateId}/`,
    },
    activityRequest: (id: QueryParam) =>
      `${apiUrl}/browser/applications/${id}/send-status-request/`,
    candidates: {
      _root: `${apiUrl}/browser/applications/`,
      candidate: (candidateId: QueryParam) =>
        `${apiUrl}/browser/applications/${candidateId}/`,
    },
    offerParser: `${apiUrl}/browser/ai-offer-parser/`,
    collections: {
      _root: `${apiUrl}/browser/collections/`,
      collection: (id: QueryParam) => ({
        _root: `${apiUrl}/browser/collections/${id}/`,
        collaborators: `${apiUrl}/browser/collections/${id}/collaborators/`,
        unsubscribe: `${apiUrl}/browser/collections/${id}/unsubscribe/`,
      }),
      possibleCollaborators: `${apiUrl}/browser/collections/possible-collaborators/`,
    },
    chart: `${apiUrl}/browser/chart/`,
    talentPools: `${apiUrl}/browser/talent-pools/`,
  },

  cvGenerator: {
    context: {
      _root: `${apiUrl}/cv-generator/context/`,
      detail: (id: QueryParam) => ({
        _root: `${apiUrl}/cv-generator/context/${id}/`,
        translate: `${apiUrl}/cv-generator/context/${id}/translate/`,
        download: `${apiUrl}/cv-generator/context/${id}/download/`,
        adjustToOffer: `${apiUrl}/cv-generator/context/${id}/adjust-to-offer/`,
        groupSkills: `${apiUrl}/cv-generator/context/${id}/group-skills/`,
      }),
      fromFile: `${apiUrl}/cv-generator/context/from-file/`,
      fromCandidateId: (id: QueryParam) =>
        `${apiUrl}/cv-generator/context/from-candidate-id/${id}/`,
      retry: (id: QueryParam) => `${apiUrl}/cv-generator/context/${id}/retry/`,
      downloadZip: `${apiUrl}/cv-generator/context/download-zip/`,
    },
    compareWithOffer: (id: QueryParam) =>
      `${apiUrl}/cv-generator/compare-with-offer/${id}/`,
    render: `${apiUrl}/cv-generator/render/`,
    feedback: `${apiUrl}/cv-generator/feedback/`,
    userUsageData: `${apiUrl}/cv-generator/user-usage-data/`,
  },
  common: {
    languages: {
      _root: `${apiUrl}/common/languages/`,
      language: (languageId: QueryParam) =>
        `${apiUrl}/common/languages/${languageId}/`,
    },
    skills: {
      _root: `${apiUrl}/common/skills/`,
      skill: (skillId: QueryParam) => `${apiUrl}/common/skills/${skillId}/`,
    },
    countries: {
      _root: `${apiUrl}/common/countries/`,
      countries: (countriesId: QueryParam) =>
        `${apiUrl}/common/countries/${countriesId}/`,
    },
    cities: {
      _root: `${apiUrl}/common/cities/`,
      cities: (citiesId: QueryParam) => `${apiUrl}/common/cities/${citiesId}/`,
    },
    waitingList: `${apiUrl}/common/waiting-list/`,
  },
  geolocation: `${apiUrl}/geoloc/`,
  profile: {
    me: {
      _root: `${apiUrl}/profile/me/`,
      activeStatus: `${apiUrl}/profile/me/active-status/`,
    },
    deleteRequest: `${apiUrl}/profile/delete-request/`,
    onboardingAccomplished: `${apiUrl}/profile/onboarding-accomplished/`,
  },
  auth: {
    activate: `${apiUrl}/auth/activate/`,
    activateAndResetPassword: `${apiUrl}/auth/activate-and-reset-password/`,
    activateRequest: `${apiUrl}/auth/activate-request/`,
    register: `${apiUrl}/auth/register/`,
    resetPassword: `${apiUrl}/auth/reset-password/`,
    resetPasswordRequest: `${apiUrl}/auth/reset-password-request/`,
    token: {
      _root: `${apiUrl}/auth/token/`,
      refresh: `${apiUrl}/auth/token/refresh/`,
    },
    logout: `${apiUrl}/auth/logout/`,
    activeCandidateStatusRequest: `${apiUrl}/auth/active-candidate-status-request/`,
    customerRegister: `${apiUrl}/auth/customer-register/`,
    google: {
      _root: `${apiUrl}/auth/google/`,
      oneTap: `${apiUrl}/auth/google/one_tap/`,
    },
  },
  parseDataFromCv: `${apiUrl}/parse-data-from-cv/`,
  feedback: {
    _root: `${apiUrl}/feedback/`,
    average: `${apiUrl}/feedback/average/`,
  },
  tests: {
    available: {
      _root: `${apiUrl}/tests/available/`,
      test: (id: QueryParam) => `${apiUrl}/tests/available/${id}/`,
      start: (id: QueryParam) => `${apiUrl}/tests/available/${id}/start/`,
    },
    certificates: {
      _root: `${apiUrl}/tests/certificates/`,
      certificate: (id: QueryParam) => `${apiUrl}/tests/certificates/${id}/`,
    },
    started: {
      _root: `${apiUrl}/tests/started/`,
      forRecruiter: (applicationId: QueryParam) =>
        `${apiUrl}/tests/started/for-recruiter/${applicationId}/`,
      test: (testId: QueryParam) => ({
        _root: `${apiUrl}/tests/started/${testId}/`,
        certificate: `${apiUrl}/tests/started/${testId}/certificate/`,
        questions: {
          question: (questionId: QueryParam) => ({
            answer: `${apiUrl}/tests/started/${testId}/questions/${questionId}/answer/`,
          }),
        },
      }),
    },
  },
  candidateMatching: {
    _root: `${apiUrl}/candidate-matching/`,
    single: (id: QueryParam) => `${apiUrl}/candidate-matching/${id}/`,
  },
  payments: {
    stripe: {
      checkout: (id: QueryParam) => `${apiUrl}/payments/stripe/checkout/${id}/`,
      customerPortal: `${apiUrl}/payments/stripe/customer_portal/`,
      prices: `${apiUrl}/payments/stripe/prices/`,
      packages: `${apiUrl}/payments/stripe/packages/`,
      userCurrentBoughtPrice: `${apiUrl}/payments/stripe/user-current-bought-price/`,
      hasUserUsedTrial: `${apiUrl}/payments/stripe/has-user-used-trial/`,
    },
  },
  jobMatching: {
    offer: {
      _root: `${apiUrl}/job-matching/offer/`,
      generateAiOfferDetails: `${apiUrl}/job-matching/offer/generate-ai-offer-details/`,
      detail: (id: QueryParam) => ({
        _root: `${apiUrl}/job-matching/offer/${id}/`,
        candidates: {
          _root: `${apiUrl}/job-matching/offer/${id}/candidates/`,
          candidate: (candidateId: QueryParam) =>
            `${apiUrl}/job-matching/offer/${id}/candidates/${candidateId}/`,
        },
        add: {
          fromExternalCandidates: `${apiUrl}/job-matching/offer/${id}/add/from-external-candidates/`,
          fromMindpal: `${apiUrl}/job-matching/offer/${id}/add/from-mindpal/`,
        },
        retry: {
          _root: `${apiUrl}/job-matching/offer/${id}/retry/`,
          candidate: (candidateId: QueryParam) =>
            `${apiUrl}/job-matching/offer/${id}/retry/${candidateId}/`,
        },
        downloadCsv: `${apiUrl}/job-matching/offer/${id}/download-csv/`,
      }),
    },
    cvParser: {
      fromFile: `${apiUrl}/job-matching/cv-parser/from-file/`,
      fromId: (id: QueryParam) =>
        `${apiUrl}/job-matching/cv-parser/from-id/${id}/`,
    },
  },
  externalCandidates: {
    candidates: {
      _root: `${apiUrl}/external-candidates/candidates/`,
      detail: (id: QueryParam) => ({
        root: `${apiUrl}/external-candidates/candidates/${id}/`,
        retry: `${apiUrl}/external-candidates/candidates/${id}/retry/`,
      }),
      fromFile: `${apiUrl}/external-candidates/candidates/from-file/`,
      fromRecruiteeId: `${apiUrl}/external-candidates/candidates/from-recruitee-id/`,
    },
    collections: {
      _root: `${apiUrl}/external-candidates/collections/`,
      detail: (id: QueryParam) =>
        `${apiUrl}/external-candidates/collections/${id}/`,
    },
  },
  settings: {
    recruiter: {
      mine: `${apiUrl}/settings/recruiter/mine/`,
    },
    templateRequest: {
      _root: `${apiUrl}/settings/template_request/`,
      id: (id: QueryParam) => `${apiUrl}/settings/template_request/${id}/`,
    },
  },
};
