import classNames from 'classnames';

import Text from './Text';
import { Accordion, AccordionContent, AccordionItem } from './ui/accordion';

const ErrorAccordion = ({
  error,
  className,
}: {
  error?: string;
  className?: string;
}) => {
  return (
    <div className={classNames(className)}>
      <Accordion type="single" value={error ? 'show' : undefined}>
        <AccordionItem value="show" className="border-0">
          <AccordionContent contentWrapClassName="pb-0">
            <Text variant="body-small" className="leading-5 text-error">
              {error}
            </Text>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ErrorAccordion;
