import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import authService from '@/services/auth/auth';

import { apiRoutes } from './config';

export enum CurrencyOptions {
  'PLN' = 'PLN',
  'EUR' = 'EUR',
  'USD' = 'USD',
}
export enum PaymentPeriodOptions {
  'Hourly' = 'HOUR',
  'Monthly' = 'MONTH',
  'Annually' = 'YEAR',
}
export enum JobStartOptions {
  'As soon as possible' = 'ASAP',
  'Up to 1 month' = 'UP_TO_ONE_MONTH',
  'Between 1 and 3 months' = 'BETWEEN_ONE_AND_THREE_MONTHS',
}
export enum AvailabilityOptions {
  'Full time' = 'FULL_TIME',
  'Part time' = 'PART_TIME',
  'Consultant' = 'CONSULTANT',
}
export enum SeniorityLevelOptions {
  'Intern' = 'INTERN',
  'Junior' = 'JUNIOR',
  'Mid' = 'MID',
  'Senior' = 'SENIOR',
  'Lead / Architect' = 'LEAD',
}
export enum WorkModeOptions {
  'REMOTE' = 'REMOTE',
  'HYBRID' = 'HYBRID',
  'ON_SITE' = 'ON_SITE',
}

export enum EnglishLevelOptions {
  'B1' = 'B1',
  'B2' = 'B2',
  'C1' = 'C1',
  'C2' = 'C2',
}

export const CurrencySchema = string()
  .required()
  .oneOf([...Object.values(CurrencyOptions), '', 'UNKNOWN']);
export type Currency = InferType<typeof CurrencySchema>;

export const PaymentPeriodSchema = string()
  .required()
  .oneOf([...Object.values(PaymentPeriodOptions), '']);
export type PaymentPeriod = InferType<typeof PaymentPeriodSchema>;

export const JobStartSchema = string()
  .required()
  .oneOf([...Object.values(JobStartOptions), '']);
export type JobStart = InferType<typeof JobStartSchema>;

export const AvailabilitySchema = string()
  .required()
  .oneOf([...Object.values(AvailabilityOptions), '']);
export type Availability = InferType<typeof AvailabilitySchema>;

export const SeniorityLevelSchema = string()
  .required()
  .oneOf([...Object.values(SeniorityLevelOptions), '']);
export type SeniorityLevel = InferType<typeof SeniorityLevelSchema>;

export const EnglishLevelSchema = string()
  .required()
  .oneOf([...Object.values(EnglishLevelOptions), '']);
export type EnglishLevel = InferType<typeof SeniorityLevelSchema>;

export const WorkModeSchema = string().oneOf(Object.values(WorkModeOptions));
export type WorkMode = InferType<typeof WorkModeSchema>;

export const ProfileExperienceSchema = object({
  companyName: string().nullable().defined(),
  title: string().nullable().defined(),
  description: string().nullable().defined(),
  location: string().nullable().defined(),
  start: string().nullable().defined(),
  end: string().nullable().defined(),
});
export type ProfileExperience = InferType<typeof ProfileExperienceSchema>;

export const ProfileEducationSchema = object({
  schoolName: string().nullable().defined(),
  degree: string().nullable().defined(),
  start: string().nullable().defined(),
  end: string().nullable().defined(),
  major: string().nullable().defined(),
  description: string().nullable().defined(),
});
export type ProfileEducation = InferType<typeof ProfileEducationSchema>;

export const ApiProfileSchema = object({
  email: string().email().required(),
  name: string().required(),
  cvUrl: string().url().ensure(),
  source: string(),
  phone: string(),
  githubUrl: string().url().defined(),
  linkedinUrl: string().url().defined(),
  videoUrl: string().url().ensure(),
  coverLetter: string().defined(),
  skills: array()
    .of(
      object({
        skill: number().required(),
        yearsOfExperience: number().nullable().defined(),
      })
    )
    .required(),
  wantToUseSkills: array().of(number().required()).required(),
  languages: array()
    .of(
      object({
        language: number().required(),
        level: number().nullable().defined(),
      })
    )
    .required(),
  jobStart: JobStartSchema.notRequired().ensure(),
  availability: AvailabilitySchema.notRequired().ensure(),
  seniorityLevel: SeniorityLevelSchema.notRequired().ensure(),
  englishLevel: string(),
  city: number().nullable().defined(),
  country: number().nullable().defined(),
  commercialExperience: number().nullable().defined(),
  relocation: boolean().nullable().defined(),
  financialExpectationsMax: number().nullable().defined(),
  financialExpectationsMin: number().nullable().defined(),
  currency: CurrencySchema.notRequired().ensure(),
  paymentPeriod: PaymentPeriodSchema.notRequired().ensure(),
  experience: array().of(ProfileExperienceSchema).required(),
  education: array().of(ProfileEducationSchema).required(),
  accountType: string().defined(),
  isActiveCandidate: boolean().required(),
  showActiveCandidateNotification: boolean(),
  deleteRequestedAt: string().nullable().defined(),
  isDeleteRequested: boolean(),
  collectionsOnboardingAccomplished: boolean().defined(),
  dashboardOnboardingAccomplished: boolean().defined(),
  generatorOnboardingAccomplished: boolean().defined(),
  jobMatchingOnboardingAccomplished: boolean().defined(),
  settingsOnboardingAccomplished: boolean().defined(),
});
export type ApiProfile = InferType<typeof ApiProfileSchema>;
export type Profile = Omit<ApiProfile, 'skills' | 'languages'> & {
  skills: [number, number | undefined][];
  languages: [number, number][];
};

export const getProfileData = async (): Promise<Profile> => {
  const { data } = await authService.get(apiRoutes.profile.me._root);
  ApiProfileSchema.validate(data);
  const validData = ApiProfileSchema.cast(data);
  return {
    ...validData,
    languages: validData.languages.map(({ language, level }) => [
      language,
      level ?? 0,
    ]),
    skills: validData.skills.map(({ skill, yearsOfExperience }) => [
      skill,
      yearsOfExperience ?? undefined,
    ]),
  };
};

export const editProfile = async (formData: Partial<Profile>) => {
  const { data } = await authService.patch(apiRoutes.profile.me._root, {
    ...formData,
    city: formData.city === -1 ? null : formData.city ?? null,
    country: formData.country ?? null,
    languages: formData.languages?.map(([language, level]) => ({
      language,
      level,
    })),
    skills: formData.skills?.map(([skill, yearsOfExperience]) => ({
      skill,
      yearsOfExperience,
    })),
    phone:
      typeof formData.phone === 'string' && formData.phone.length > 0
        ? `+${
            formData.phone.includes('+')
              ? formData.phone.split('+')[1]
              : formData.phone
          }`
        : undefined,
  });
  ApiProfileSchema.validate(data);
  const validData = ApiProfileSchema.cast(data);
  return {
    ...validData,
    languages: validData.languages.map(({ language, level }) => [
      language,
      level,
    ]),
    skills: validData.skills.map(({ skill, yearsOfExperience }) => [
      skill,
      yearsOfExperience,
    ]),
  };
};

export const requestAccountDelete = async (requestData: {
  password: string;
}) => {
  const { data } = await authService.post(apiRoutes.profile.deleteRequest, {
    password: requestData.password,
  });
  ApiProfileSchema.validate(data);
  const validData = ApiProfileSchema.cast(data);
  return {
    ...validData,
    languages: validData.languages.map(({ language, level }) => [
      language,
      level,
    ]),
    skills: validData.skills.map(({ skill, yearsOfExperience }) => [
      skill,
      yearsOfExperience,
    ]),
  };
};

export const postOnboardingAccomplished = (
  onboarding:
    | 'JOB_MATCHING'
    | 'DASHBOARD'
    | 'GENERATOR'
    | 'COLLECTIONS'
    | 'SETTINGS'
) => {
  return authService.post(apiRoutes.profile.onboardingAccomplished, {
    onboarding_type: onboarding,
  });
};

export const logout = async () => authService.post(apiRoutes.auth.logout);
