import type { SortingState } from '@tanstack/react-table';
import QueryString from 'qs';
import toast from 'react-hot-toast';
import type { InferType } from 'yup';
import { array, date, number, object, string } from 'yup';

import authService from '@/services/auth/auth';

import { DjangoDataSchema } from './common';
import { apiRoutes } from './config';
import { CvContextListElementSchema } from './cvGenerator';

const noteSchema = object({
  id: number().defined(),
  text: string().defined(),
  createdAt: date().defined(),
  updatedAt: date().defined(),
}).defined();

export type Note = InferType<typeof noteSchema>;

export const externalCandidateListSchema = object({
  id: number().defined(),
  name: string().defined(),
  email: string().defined(),
  phoneNumber: string().defined(),
  cvFile: string().nullable().defined(),
  collections: array()
    .of(object({ id: number().defined(), title: string().defined() }))
    .defined(),
  cvContexts: array().of(CvContextListElementSchema).defined(),
  updatedAt: date().defined(),
  createdAt: date().defined(),
  status: string().oneOf(['PROCESSING', 'READY', 'FAILED']).defined(),
  notes: array().of(noteSchema).defined(),
  linkedinUrl: string().defined(),
  githubUrl: string().defined(),
  convertedSalary: number().nullable().defined(),
  salary: number().nullable().defined(),
  currency: string().oneOf(['PLN', 'EUR', 'USD']).defined(),
  paymentPeriod: string().oneOf(['HOUR', 'MONTH', 'YEAR']).defined(),
}).defined();

export type ExternalCandidateList = InferType<
  typeof externalCandidateListSchema
>;

export const externalCandidateSchema = object({
  id: number().defined(),
  name: string().defined(),
  email: string().defined(),
  phoneNumber: string().defined(),
  cvFile: string().nullable().defined(),
  collections: array()
    .of(object({ id: number().defined(), title: string().defined() }))
    .defined(),
  cvContexts: array().of(CvContextListElementSchema).defined(),
  updatedAt: date().defined(),
  createdAt: date().defined(),
  status: string().oneOf(['PROCESSING', 'READY', 'FAILED']).defined(),
  notes: array().of(noteSchema).defined(),
  generationType: string().oneOf(['RECRUITEE_ID', 'FILE']).defined(),
  recruiteeId: number().nullable().defined(),
  startDate: date().nullable().defined(),
  salary: number().nullable().defined(),
  paymentPeriod: string().oneOf(['HOUR', 'MONTH', 'YEAR', '']).defined(),
  currency: string().oneOf(['PLN', 'EUR', 'USD', '']).defined(),
  linkedinUrl: string().defined(),
  githubUrl: string().defined(),
}).defined();

export type ExternalCandidate = InferType<typeof externalCandidateSchema>;

export const candidateCollectionSchema = object({
  id: number().defined(),
  title: string().defined(),
  description: string().defined(),
  candidates: array().of(externalCandidateSchema).defined(),
});
export type ExternalCandidatesCollection = InferType<
  typeof candidateCollectionSchema
>;
export const postCvConstruct = async (formData: {
  cvData: {
    cv?: File;
    id?: string;
  };
  language: string;
  anonymization: boolean;
  generateResume: boolean;
  forceGenerate?: boolean;
  offerDescription?: string;
  offerTitle?: string;
  fieldsToAdjustToOffer?: string[];
  collections?: number[];
}) => {
  const requestBody = {
    language: formData.language,
    offer_title: formData.offerTitle,
    offer_description: formData.offerDescription,
    fields_to_adjust_to_offer: formData.fieldsToAdjustToOffer,
    collections: formData.collections,
  };
  const postCv = async () =>
    authService.post(
      apiRoutes.externalCandidates.candidates.fromFile,
      { cv_file: formData.cvData.cv, ...requestBody },
      {
        params: {
          force_generate: formData.forceGenerate,
          generate_cv_context: formData.generateResume,
          skip_anonymization: !formData.anonymization,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  const postCvFromId = async () =>
    authService.post(
      apiRoutes.externalCandidates.candidates.fromRecruiteeId,
      { recruitee_id: formData.cvData.id, ...requestBody },
      {
        params: {
          forceGenerate: formData.forceGenerate,
          generate_cv_context: formData.generateResume,
          skip_anonymization: !formData.anonymization,
        },
      }
    );

  const { data, status } = await (formData.cvData.id
    ? postCvFromId()
    : postCv());
  const validData = await externalCandidateSchema.validate(data);
  if (status === 220)
    toast.error(
      'We uploaded your candidate to database, but unfortunately you exceeded the limit of generated Resumes.'
    );
  return validData;
};

export const getExternalCandidates = async ({
  offset,
  pageSize,
  search,
  collections,
  salary_order,
  target_currency,
  target_period,
}: {
  offset?: number;
  pageSize?: number;
  search?: string;
  collections?: number[];
  salary_order?: SortingState;
  target_currency?: string;
  target_period?: string;
}) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.candidates._root,
    {
      params: {
        offset,
        limit: pageSize,
        search,
        collections,
        target_currency,
        target_period,
        salary_order: salary_order?.map(
          ({ desc, id }) => `${desc ? '-' : ''}${id}`
        ),
      },
      paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: 'repeat' }),
    }
  );
  return DjangoDataSchema(externalCandidateListSchema).validate(data);
};

export const getExternalCandidate = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.candidates.detail(id).root
  );
  return externalCandidateSchema.validate(data);
};
export const getExternalCandidatesCollections = async () => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.collections._root,
    { params: { offset: 0, limit: 9999 } }
  );
  return DjangoDataSchema(candidateCollectionSchema).validate(data);
};

export const postExternalCandidatesCollection = async ({
  description,
  title,
  candidates,
}: {
  title: string;
  description: string;
  candidates?: number[];
}) => {
  const { data } = await authService.post(
    apiRoutes.externalCandidates.collections._root,
    {
      title,
      description,
      candidates,
    }
  );
  return candidateCollectionSchema.validate(data);
};

export const patchCollection = async ({
  id,
  data,
}: {
  id: number;
  data: Partial<{ name: string; description: string; candidates: number[] }>;
}) =>
  authService.patch(apiRoutes.externalCandidates.collections.detail(id), data);

export const patchExternalCandidate = async ({
  id,
  data,
}: {
  id: number;
  data: Partial<
    Omit<
      ExternalCandidate,
      | 'id'
      | 'status'
      | 'generationType'
      | 'recruiteeId'
      | 'cvFile'
      | 'cvContext'
      | 'updatedAt'
      | 'createdAt'
      | 'collections'
      | 'notes'
    > & { collections: number[] } & { notes: { text: string }[] }
  >;
}) => {
  return authService.patch(
    apiRoutes.externalCandidates.candidates.detail(id).root,
    data
  );
};

export const deleteExternalCandidates = async (ids: number[]) => {
  return Promise.all(
    ids.map((id) =>
      authService.delete(
        apiRoutes.externalCandidates.candidates.detail(id).root
      )
    )
  );
};

export const deleteExternalCandidatesCollection = async (id: number) =>
  authService.delete(apiRoutes.externalCandidates.collections.detail(id));

export const postRetryExternalCandidate = async (id: number) =>
  authService.post(apiRoutes.externalCandidates.candidates.detail(id).retry);
