/* eslint-disable no-param-reassign */

import Cookies from 'universal-cookie';
import { object, string } from 'yup';

import type { Register } from '@/pages/auth/signup';

import { axios } from '../axios';
import { apiRoutes } from '../config';

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    config.headers['X-CSRFToken'] = cookies.get('csrftoken');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err?.response?.config;
    if (
      err?.response?.status === 401 &&
      !originalConfig._retry &&
      originalConfig?.url !== apiRoutes.auth.token.refresh
    ) {
      originalConfig._retry = true;
      try {
        await axios.post(apiRoutes.auth.token.refresh);
        return await axios(originalConfig);
      } catch (_error) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);

export const login = async (data: { email: string; password: string }) => {
  const response = await axios.post(apiRoutes.auth.token._root, data);
  return response;
};

export const register = async (data: Register) => {
  const response = await axios.post(apiRoutes.auth.customerRegister, data);
  return response;
};

export const requestAccountActivate = async (email: string) => {
  string().email().required().validate(email);
  const { data } = await axios.post(apiRoutes.auth.activateRequest, { email });
  return data;
};

export const activateAccount = async (token: string) => {
  string().defined().validate(token);
  const { data } = await axios.post(apiRoutes.auth.activate, {
    token,
  });
  return data;
};

export const postActiveCandidateStatusRequest = async (data: {
  token: string;
  userChoice: boolean;
}) => {
  return axios.post(apiRoutes.auth.activeCandidateStatusRequest, data);
};

export const getGoogleAuth = async () => {
  const { data } = await axios.get(apiRoutes.auth.google._root, {
    withCredentials: true,
  });
  const validData = object({
    authorizationUrl: string().url().defined(),
  }).validate(data);
  return validData;
};

export const postGoogleOneTap = async (credential: string) => {
  return axios.post(apiRoutes.auth.google.oneTap, { token: credential });
};

export default axios;
