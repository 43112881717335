import type { ComponentProps, MouseEvent } from 'react';

// eslint-disable-next-line import/no-cycle
import Button from './Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from './ui/dialog';

type Props = {
  confirmMessage: string;
  onConfirm: (e: MouseEvent<HTMLButtonElement>) => void;
} & ComponentProps<typeof Dialog>;

const ConfirmationDialog = ({
  confirmMessage,
  onConfirm,
  onOpenChange,
  ...props
}: Props) => {
  return (
    <Dialog {...props} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>Are you sure?</DialogHeader>
        <DialogDescription className="text-neutral-700">
          {confirmMessage}
        </DialogDescription>
        <div className="row flex w-full justify-end gap-3">
          <Button
            variant="secondary"
            size="md"
            onClick={() => onOpenChange?.(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            onClick={(e) => {
              onConfirm(e);
              onOpenChange?.(false);
            }}
          >
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
